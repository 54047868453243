import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IrdetoAuthService } from './irdeto-auth.service';
import { Audience } from 'src/app/shared/utils/audience';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root',
})
export class AudienceService {
  constructor() {}

  getAudienceForScope(serviceName: string) {
    return Audience[serviceName].show;
  }

  getAudienceForToken(serviceName) {
    return Audience[serviceName].manage;
  }
}
