export enum RouteConstants {
  VOD_CONTENT = 'content_management/vod',
  CHANNEL = 'content_management/channel',
  EPG = 'content_management/epg',
  DASHBOARD = 'dashboard',
  CONTENT_MANAGEMENT = 'content_management',
  ADVERTISEMENTS = 'advertisement',
  ERROR = 'error',
  COMING_SOON = 'error/comingSoon',
  WORKFLOWS = 'workflows_&_tasks',
  SUBSCRIBER_MANAGEMENT = 'user/household_device_manager',
  PLAN_CONFIGURATOR = 'content_management/entitlement_control',
  HUMAN_TASKS = 'content_management/meta_control',
  USER_AUDIT='observability/audit_logs',
  WELCOME = 'welcome',
  FORBIDDEN = 'error/forbidden',
  NOTFOUND='error/not_found',
  UNAUTHORSED='error/unauthorised',
  FeatureUnavailable='error/feature-unavailable'
}
