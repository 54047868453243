import { Injectable, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { IrdetoAuthService } from '../services/irdeto-auth.service';
import { PermissionService } from "../services/permission.service";
import {firstValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class IrdetoAuthGuardService {
  constructor(private irdetoAuthService: IrdetoAuthService, private permissionService: PermissionService) {}

  async canActivate(): Promise<boolean> {
    const isAllowed = this.irdetoAuthService.isIdtokenValid();
    if (isAllowed) {
      await firstValueFrom(this.permissionService.fetchAllowedServices());
      return true;
    } else {
      this.irdetoAuthService.initAuthFlow();
      return false;
    }
  }
}

export const irdetoAuthGuard: CanActivateFn = () => {
  const authService = inject(IrdetoAuthGuardService);
  return authService.canActivate();
};
