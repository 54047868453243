import {from, Observable, of} from 'rxjs';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {IrdetoAuthService} from './irdeto-auth.service';
import {AccessTokenService} from "./access-token.service";
import {EnvService} from "./env.service";
import {AudienceService} from './audience.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private scopes: { [key: string]: string[] } = {};
  private servicesLoaded = false;
  public currentServiceId: string | null = null;
  allServicesData: any[] = [];
  private serviceData: { [key: string]: any } = {};

  constructor(private auth: IrdetoAuthService, private tokenService: AccessTokenService, private audienceService: AudienceService, private envService: EnvService) {
  }

  fetchAllowedServices(): Observable<any> {
    if (this.servicesLoaded) {
      return of(Object.keys(this.serviceData));
    }

    return from(this.auth.requestServices()).pipe(
      take(1),
      tap((exercisableServices) => {
        this.allServicesData = exercisableServices;
        exercisableServices.forEach((el) => {
          this.serviceData[el.serviceId] = {...el}
        });
        this.servicesLoaded = true;
      })
    );
  }

  fetchTenants(): Observable<{[key:string]:string }> {
    return of(this.serviceData).pipe(
      map(serviceData => {
        const tenantList = Object.values(serviceData)
          .reduce((acc: any, { tenantId, tenantName }) => {
            acc[tenantId] = tenantName;
            return acc;
          }, {});
        return tenantList;
      })
    );
  }

  fetchScopesForService(serviceName: string, audience: string, tenantId?: string): Observable<string[]> {
    const serviceId = this.envService.getConfig()[serviceName];
    if (this.scopes[serviceId]) {
      return of(this.scopes[serviceId]);
    }
    return from(this.auth.requestScopes({
      tenant: tenantId ? tenantId : this.serviceData[serviceId]['tenantId'],
      audience,
      service: serviceId
    })).pipe(
      take(1),
      tap((scopes: string[]) => {
        this.scopes[`${serviceId}:${audience}`] = scopes;
      })
    );
  }

  hasService(serviceId: string): boolean {
    return this.serviceData[serviceId];
  }

  getScopes() {
    return Object.keys(this.scopes);
  }

  hasScope(serviceName: string, scope: string, audience?: string): boolean {
    const serviceId = this.envService.getConfig()[serviceName];
    if (!audience) {
      audience = this.audienceService.getAudienceForScope(serviceName);
    }
    return this.scopes[`${serviceId}:${audience}`]?.includes(scope) ?? false;
  }

// will be used in future
  setCurrentServiceId(currentServiceId: string) {
    this.currentServiceId = currentServiceId
  }

// will be used in future
  geCurrentServiceId(): string | null {
    return this.currentServiceId;
  }

  setParamToGetCurrentServiceAccessToken(serviceName: string, audience: string, tenantId?: string): Observable<void> {
    const serviceId = this.envService.getConfig()[serviceName];
    if (!this.scopes[`${serviceId}:${audience}`]) {

      return this.fetchScopesForService(serviceName, audience).pipe(
        switchMap(() => this.setParamToGetCurrentServiceAccessToken(serviceName, audience))
      );
    } else {
      const dataToBePassed = {
        scopes: this.scopes[`${serviceId}:${audience}`],
        tenantId: tenantId ?  tenantId : this.serviceData[serviceId]['tenantId'],
        audience,
        serviceId: serviceId,
      };
      this.tokenService.setParams(dataToBePassed);
      return of(undefined);
    }
  }

  setParamsForThirdPartyAccessToken(serviceName, audience) {
    const serviceId = this.envService.getConfig()[serviceName];
    if (this.scopes[`${serviceId}:${audience}`] && this.serviceData[serviceId]['tenantId']) {
      const dataToBePassed = {
        scopes: this.scopes[`${serviceId}:${audience}`],
        tenantId: this.serviceData[serviceId]['tenantId'],
        audience,
        serviceId: serviceId,
      };
      this.tokenService.setParams(dataToBePassed);
    }
  }

}
