import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { GraphQLModule } from './graphql.module';
import { EnvService } from './core/services/env.service';
import { IrdetoAuthService } from './core/services/irdeto-auth.service';
import { AuthInterceptor } from './core/interceptor/auth.interceptor';
import { initializeApp, irdetoAuthServiceFactory } from './core/services/app-initializer.service';


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NoopAnimationsModule,
    GraphQLModule,

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [EnvService],
      multi: true
    },
    {
      provide: IrdetoAuthService,
      useFactory: irdetoAuthServiceFactory,
      deps: [EnvService]
    }

  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
