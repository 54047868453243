export const Audience = {
  contentManagementService: {
    show: 'iflex-cms',
    manage: 'iflex-cms',
  },
  packageConfiguratorService: {
    show: 'ux', // it is for UI
    manage: 'manage', // It is for Token
  },
  workflowsManagementService: {
    show: 'ux', // it is for UI
    manage: 'human-tasks', // It is for Token
  },
  userManagementService: {
    show: 'ux',
    manage: 'manage',
  },
  advertisingService: {
    show: 'ux',
    manage: 'manage',
  },
  goannaService: {
    show: 'goanna',
    manage: 'goanna',
  },
  userAuditService: {
    show: 'audit-api',
    manage: 'audit-api',
  },
};
