import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of, from} from 'rxjs';
import {map} from 'rxjs/operators';
import {IrdetoAuthService} from "./irdeto-auth.service";

@Injectable({
  providedIn: 'root',
})
export class AccessTokenService {
  private accessToken: string | null = null;
  private params: any;

  constructor(private http: HttpClient, private irdetoAuthService: IrdetoAuthService) {
  }

  setParams(params: any): void {
    this.params = params;
    this.accessToken = null;
  }

  fetchAccessToken(): Observable<string | null> {
    if (this.accessToken) {
      return of(this.accessToken);
    } else if (this.params) {
      return from(this.irdetoAuthService.getAccessToken(this.params)).pipe(
        map((response: any) => {
          this.accessToken = response;
          return this.accessToken;
        })
      );
    } else {
      throw new Error('Token parameters are not set');
    }
  }

  clearAccessToken() {
    this.accessToken = null;
  }
}
