import { Injectable } from '@angular/core';
import { IrdetoAuth } from 'irdeto-auth-js';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class IrdetoAuthService extends IrdetoAuth {
  constructor(private envService: EnvService) {
    const config = envService.getConfig();
    if (Object.keys(config).length === 0) {
      throw new Error('Configuration not loaded');
    }
    super({
      clientId: config['authClientId'],
      redirectUri: config['redirectUri'],
      apiDomain: config['authApiDomain'],
    });
  }
}

