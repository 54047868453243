import { Injectable } from '@angular/core';

interface EnvironmentConfig {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class EnvService {
  private config: EnvironmentConfig = {};
  private configLoaded = false;

  loadConfig(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.configLoaded) {
        resolve();
        return;
      }

      const browserWindow = window as any; // Explicitly type window as any
      const browserWindowEnv = (browserWindow.__env || {}) as EnvironmentConfig;

      for (const key in browserWindowEnv) {
        if (browserWindowEnv.hasOwnProperty(key)) {
          this.config[key] = browserWindowEnv[key];
        }
      }

      if (Object.keys(this.config).length === 0) {
        reject('Configuration not loaded');
      } else {
        this.configLoaded = true;
        resolve();
      }
    });
  }

  getConfig() {
    return this.config;
  }
}
