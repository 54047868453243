import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { AccessTokenService } from '../services/access-token.service';
import { StatusCodeConstant } from '../constants/status-code.constant';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private tokenService: AccessTokenService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.tokenService.fetchAccessToken().pipe(
      switchMap((token) => {
        const clonedRequest = req.clone({
          headers: req.headers.set('Authorization', `${token}`)
        });
        return next.handle(clonedRequest).pipe(
          catchError((error) => {
            if (error.status === StatusCodeConstant.UNAUTHORIZED_ACCESS) {
              this.tokenService.clearAccessToken();
              return this.tokenService.fetchAccessToken().pipe(
                switchMap((newToken) => {
                  const retryRequest = req.clone({
                    headers: req.headers.set('Authorization', `${newToken}`)
                  });
                  return next.handle(retryRequest);
                })
              );
            }
            return throwError(error);
          })
        );
      })
    );
  }
}
